<template>
  <div class="layout-main">
    <div v-show="employee.corpid">
      <main-header></main-header>

      <div class="container">
        <div class="aside">
          <aside-menu></aside-menu>
        </div>
        <div class="main">
          <el-scrollbar style="height: 100%;">
            <el-breadcrumb separator="/" class="breadcrumb">

              <el-breadcrumb-item v-for="(item, index) in metas" :key="index" v-if="item.meta && item.meta.title"><span
                  class="text-color"> {{ item.meta.title }}</span>
              </el-breadcrumb-item>
              <router-back v-if="metas != null && metas[metas.length - 1].meta.showBack === true"></router-back>
            </el-breadcrumb>

            <div class="main-container">

              <router-view class="main-bg"></router-view>

            </div>

            <div class="slog-container">
              <!--            <p class="slog">物联助力咖啡业务更高效</p>-->
              <p>云咖信息©为您更好使用物联云服务</p>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <div v-show="!employee.corpid" class="select">
      <p class="tip">点击选取需要操作的企业号</p>
      {{ employee.corpid }}
      <div class="select-container">
        <div v-for="(corpid, idx) in corpids" :key="idx" class="select-item"
          :class="{ active: selectCorpid === corpid.corpid }" @click="selectCorpid = corpid.corpid">
          {{ corpid.name }}
        </div>
      </div>

      <el-button type="primary" @click="handleEnterSystem">进入系统</el-button>
    </div>
  </div>
</template>

<script>
import { employeeModules } from "@/api/account/employee";
import { thirdAgentInit, thirdCompanies } from "@/api/work/company";
import CompanyHeader from '@/components/Header/company.vue';
import MainHeader from '@/components/Header/index.vue';
import AsideMenu from '@/components/Menu/index.vue';
import { getToken, removeToken } from '@/util/auth';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: '',
  components: {
    AsideMenu,
    MainHeader,
    CompanyHeader,
  },
  computed: {
    ...mapGetters(['accountPage', 'employee']),
  },

  mounted: function () {
    if (!this.employee.corpid) {
      this.loadCorpid()
    } else {
      this.initSystem(this.employee.corpid)
      this.loadEmployeeMenus()
    }
  },
  watch: {
    "$route": function (val) {
      this.getBreadcrumb()
    },
  },
  data: function () {
    return {
      collapse: false,
      corpids: [],
      selectCorpid: '',
      metas: [] // 路由集合
    }
  },
  methods: {
    ...mapActions(['setEnterpriseMenu', `setAccountPageByRouterName`, `setEmployeeCorpid`]),
    isHome(route) {
      return route.name === "home";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      //如果不是首页
      if (!this.isHome(matched[0])) {
        matched = [{ path: "/home", meta: { title: "首页" } }].concat(matched);
      }
      this.metas = matched;
    },

    handleEnterSystem() {
      if (!this.selectCorpid) {
        this.$message.warning('请选取操作的企业')
        return
      }

      this.initSystem(this.selectCorpid)
    },

    loadCorpid() {
      thirdCompanies({ code: this.employee.code }).then(res => {
        this.corpids = res.data || []
        if (this.corpids.length === 0) {
          setTimeout(this.loadCorpid, 500)
        }
      })
    },
    login(corpid) {
      this.corpid = corpid
      this.setEmployeeCorpid(corpid)
      this.loadEmployeeMenus()
    },

    initSystem(corpid) {
      let vue = this

      // FIXME: for test
      // vue.login(corpid)
      thirdAgentInit({
        code: this.employee.code,
        corpid: corpid,
        url: window.location.href.split("#")[0]
      }).then(res => {
        let obj = res.data
        wx.agentConfig({
          corpid: obj.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: obj.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: obj.timestamp, // 必填，生成签名的时间戳
          nonceStr: obj.nonceStr, // 必填，生成签名的随机串
          signature: obj.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: ['selectExternalContact'], //必填
          success: function (res) {
            vue.login(corpid)
          },
          fail: function (res) {
            vue.$message.error("微信配置异常")
            console.log(res)
          }
        });
      })

    },

    loadEmployeeMenus() {
      let vue = this
      let token = getToken()
      if (token) {
        employeeModules().then(res => {
          if (res.code == 0) {
            res.data = res.data || []
            if (res.data.length === 0 || res.data[0].modules === null) {
              vue.$message.warning('请联系管理员开通权限')
              removeToken()
              vue.$router.replace({ name: 'login' })
              return
            }
            let showMenus = res.data
            vue.setEnterpriseMenu({
              navMenus: showMenus,
              routerName: this.$route.name,
            })
          }
        })
      }
    }
  },
  created() {
    this.getBreadcrumb()
  }
}
</script>

<style lang="scss" scoped>
body {
  @include bg_color($background-color-light);
  @include set_font_color($--font-color, $--font-color-dark);
}


.layout-main {
  width: 100%;
  height: 100%;

  .is-vertical {
    width: 2px !important;
  }

  .container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: $_header_height;
    @include set_bg_color($background-color-light-2, $background-color-dark-1);


    .aside {
      @include set_bg_color($background-color-light, $background-color-dark);
      left: 0;
      top: $_header_height + 1px;
      bottom: 0;
      position: fixed;
      z-index: 1;
      @include set_shadow_color(#e6e6e6, #fff);


    }

    .main {
      margin-right: $--aside-question;
      height: 100%;
      overflow-y: hidden;
      margin-left: $--aside-min-width;
      @include set_bg_color($background-color-light, $background-color-dark);

      .breadcrumb {
        padding-top: $margin * 1.2;
        padding-bottom: $margin * 1;
        padding-left: $margin * 1.5;
        font-size: 16px;

        @include set_shadow_color(#e6e6e6, #fff);

      }

      .main-container {
        //  margin: $margin;
        min-height: 618px;

        .break {
          margin-bottom: $padding;

          .explain {
            font-size: 12px;
            display: inline-block;
            margin-left: $padding-8;
            @include set_font_color($--font-03-color, $--font-color-dark);
          }
        }
      }

      .main-bg {
        padding: $padding-contain;
        height: 100%;

        .break {
          margin-bottom: 0;
        }
      }
    }
  }

  .break {
    color: $_theme_color;

    i {
      vertical-align: middle;
      margin-right: 4px;
      width: 24px;
      text-align: center;
      font-size: 16px;
    }

  }

  .slog-container {
    margin-bottom: $margin;
    color: #999;
    text-align: center;
    font-size: 12px;

    .slog {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .select {
    width: 100%;
    height: 100%;
    margin: 120px auto;
    text-align: center;

    .tip {
      color: #999;
    }

    &-item {
      font-size: 16px;
      text-align: center;
      line-height: 32px;
      height: 32px;
      border: 1px solid #999;
      border-radius: 10px;
      margin: 8px 0;
      cursor: pointer;
    }

    &-container {
      width: 480px;
      height: 360px;
      margin: 0 auto;
      overflow-y: scroll;
    }

    &-item.active {
      position: relative;
      border-color: $_theme-color;
      color: $_theme-color;
    }

    &-item.active:after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "已选";
      display: block;
      font-size: 12px;
      width: 32px;
      color: white;
      height: 32px;
      background: $_theme-color;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

    &-item:hover {
      border-color: $_theme-color;
      color: $_theme-color;
    }
  }
}
</style>
