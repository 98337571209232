<template>
  <div class="header">
    <logo></logo>
<!--    <p class="slog">物联助力咖啡业务更高效</p>-->
    <div class="menus">
      <div class="menus-item"
           :class="selectNavMenu.uuid === navMenu.uuid? 'active':''"
           v-for="(navMenu, key) in navMenus"
           @click="handleMenuClick(navMenu)"
           :key="key">{{navMenu.name}}</div>
    </div>
    <div class="header-right">
      <el-popover>
        <p v-for="enterprise in enterprises" class="switch-company" @click="handleSwitchEnterprise(enterprise)">
          {{enterprise.name}}</p>

        <div slot="reference" class="transfer-company"><span class="company">{{enterprise.name}}</span><span
          class="info" @click="loadEmployeeEnterprise">[切换公司]</span></div>
      </el-popover>
      <ul>
        <!--<li> <i class="el-icon-bell"></i>通知</li>-->
        <li @click="handleOpenDoc"><i class="el-icon-document"></i></li>
        <!--        <li>-->
        <!--          <div class="tips">{{messageNum}}</div>-->
        <!--          <i class="el-icon-message-solid"></i>-->
        <!--        </li>-->
        <!--        <li><i class="el-icon-s-tools"></i></li>-->
<!--        <li @click="handleChangeTheme">-->
<!--          <i :class="mode"></i>-->
<!--        </li>-->
      </ul>
      <el-dropdown @command="handleCommand">
        <div class="employee-info">
          <img :src="src"/>
          <span>{{employee.name}}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="employee.changePassword">修改密码</el-dropdown-item>
          <el-dropdown-item command="employee.exit">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <c-dialog title="修改密码" :visible.sync="dialogFormVisible" width="32rem" height="24rem">
      <el-form size="mini">
        <el-form-item label="原始密码">
          <el-input v-model="reset.oldPassword" type="password" autocomplete="off" placeholder="请输入原始密码"></el-input>
        </el-form-item>
        <el-form-item label="新的密码">
          <el-input v-model="reset.password" type="password" autocomplete="off" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="reset.repeatedPassword" type="password" autocomplete="off"
                    placeholder="请再次输入新的密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleResetPassword">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>
  </div>

</template>

<script>

  import {changeTheme} from '@/assets/theme/setTheme.js'
  import Logo from '@/components/Logo'
  import CDialog from '@/components/Dialog'
  import {mapActions, mapGetters} from 'vuex'
  import {getToken, setToken, removeToken} from '@/util/auth'
  import {checkObj} from '@/util/checkLogin'

  import {employeeBelongEnterprise, employeeSwitchEnterprise, employeeChangePassword} from '@/api/account/employee'

  export default {
    name: '',
    components: {
      Logo,
      CDialog
    },
    computed: {
      ...mapGetters(['employee', 'navMenus', 'selectNavMenu'])
    },
    mounted: function () {
      if (this.employee.avatar) {
        this.src = this.employee.avatar
      }
      this.enterprise = JSON.parse(getToken('enterprise'))
    },
    data: function () {
      return {
        src: require('@/assets/img/avatar.jpg'),
        messageNum: 5,
        mode: 'el-icon-sunny',
        light: 'el-icon-sunny',
        dark: 'el-icon-moon',
        enterprise: {},
        dialogFormVisible: false,
        reset: {
          oldPassword: '',
          password: '',
          repeatedPassword: '',
        },
        enterprises: []
      }
    },
    methods: {
      ...mapActions(['setTheme', 'setSelectMenus']),
      showChangePassword() {
        this.dialogFormVisible = true
        this.reset = {
          oldPassword: '',
          password: '',
          repeatedPassword: '',
        }
      },
      handleOpenDoc() {
        this.$message.warning('操作文档正在完善中,敬请期待')
        return
      },
      handleMenuClick(menu) {
        this.setSelectMenus(menu)
        this.$router.push({name: menu.modules[0].webRouterName})
      },
      handleResetPassword() {
        let reset = this.reset
        let msg = checkObj(reset, [
          {value: 'oldPassword', message: '请输入原始密码'},
          {value: 'password', message: '请输入新的密码'},
          {value: 'repeatedPassword', message: '请确认新的密码'},
        ])
        if (!msg.status) {
          this.$message.warning(msg.message);
          return
        }
        if (reset.password !== reset.repeatedPassword) {
          this.$message.warning('两次密码不匹配')
          return
        }

        employeeChangePassword(this.reset).then(res => {
          this.dialogFormVisible = false
          this.$message.success('修改成功')
        })
      },
      handleChangeTheme() {
        this.resetTheme(this.mode === this.light ? 'dark' : 'light')
      },
      handleCommand(command) {
        switch (command) {
          case `employee.exit`:
            this.exit();
            break;
          case `employee.changePassword`:
            this.showChangePassword();
            break;
        }
      },
      handleSwitchEnterprise(enterprise) {
        if (this.enterprise.uuid === enterprise.uuid) {
          this.$message.warning('已在当前公司')
          return
        }
        employeeSwitchEnterprise({uuid: enterprise.uuid}).then(res => {
          setToken(res.data.token)
          setToken(JSON.stringify(enterprise), `enterprise`)
          this.$router.replace({name: "login"})
        })
      },

      loadEmployeeEnterprise() {
        if (this.enterprises.length === 0) {
          employeeBelongEnterprise().then(res => {
            this.enterprises = res.data || []
          })
        }
      },
      resetTheme(theme) {
        if (theme === 'light') {
          this.mode = this.light
        } else {
          this.mode = this.dark
        }
        window.document.documentElement.setAttribute('data-theme', theme)
        changeTheme(theme)
        this.$mq.Publish(`theme`, theme)
        this.setTheme(theme)
      },
      exit() {
        removeToken()
        this.$router.replace({name: "login"})
      }
    },
  }
</script>

<style lang="scss" scoped>

  .switch-company {
    padding-bottom: $padding-8;
    text-align: center;

  }

  body.dark .el-popover {
    background: $background-color-dark;
  }

  .switch-company:hover {
    color: $_theme_color;
    cursor: pointer;
  }

  .switch-company:last-of-type {
    padding-bottom: 0;
  }

  .header {
    line-height: $_header_line_height;
    height: $_header_height;
    display: flex;
    width: 100%;
    align-content: center;
    top: 0;
    left: 0;
    position: fixed;
    /*border-bottom: 1px solid;*/
    z-index: 99;
    // @include set_border_color($_theme-light-8, #4a4845);
    @include set_bg_color($_header_light_bg_color, $_header_dark_bg_color);
    @include set_shadow_color(#dedede, #2d2b2b);



    .logo {

    }

    .menus {
      padding-left: $padding-8;
      flex: 1;
      display: flex;

      &-item {
        width: 12.5%;
        text-align: center;
        cursor: pointer;
        @include set_font_color($_header_slog_light_color, $_header_slog_dark_color);
      }
      &-item:hover, &-item.active{
        font-weight: bold;
      }
      &-item:hover:after, &-item.active:after{
        content: '';
        height: 2px;
        width: 20%;
        margin: auto;
        display: block;
        position: relative;
        bottom: 8px;
        @include set_bg_color($_header_slog_light_color, $_header_slog_dark_color);
      }
    }


    .header-right {
      display: flex;
      align-content: center;
      justify-content: center;
      color: $--color-info;


      ul {
        display: flex;
        margin-left: 16px;

        li {
          margin: 0 16px;
          line-height: $_header_line_height;
          height: $_header_height;
          position: relative;

          i {
            vertical-align: middle;
            @include set_font_color($_header_slog_light_color, $_header_slog_dark_color);
            font-size: 20px;
            cursor: pointer;
          }

          .tips {
            padding: 2px 5px;
            font-size: 12px;
            background-color: #ff2d2c;
            position: absolute;
            right: -12px;
            top: 12px;
            line-height: 1;
            color: #fff;
            @include rounded(5px);
          }
        }
      }

      .transfer-company {
        @include set_bg_color($_theme_color-light-1, $_theme_color-dark-1);
        border-radius: 20px;
        line-height: 36px;
        padding: 0 16px;
        margin-top: 10px;

        .company {
          @include set_font_color($--font-02-color, $--font-color-dark);
        }

        .info {
          display: inline-block;
          margin-left: 6px;
          cursor: pointer;
        }

      }

      .employee-info {
        line-height: $_header_line_height;
        height: $_header_height;
        margin: 0 16px;
        cursor: pointer;


        img {
          margin: 0 8px;
          width: 24px;
          height: 24px;
          vertical-align: middle;
          border-radius: 20px;
          border: 1px solid #e4e4e4;

        }

        span {
          @include set_font_color($_header_slog_light_color, $_header_slog_dark_color);
          height: 100%;
          vertical-align: middle;
        }

        .el-icon-arrow-down {
          @include set_font_color($_header_slog_light_color, $_header_slog_dark_color);
        }
      }
    }
  }
</style>
