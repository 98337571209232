<template>

  <el-scrollbar style="height: 100%;">
    <div class="aside-menu">
      <div class="aside-menu-item" @click="handleSelect(menu.webRouterName)"
           :class="collapse? 'collapse': ''" :index="menu.uuid" v-for="(menu) in menus">
        <div class="item" :class="idx ===menu.webRouterName?'active': ''">
          <i :class="menu.icon"></i>
          <span v-show="!collapse"> {{menu.name}}</span>
        </div>
      </div>
    </div>
  </el-scrollbar>

</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  String.prototype.strLen = function() {
    var len = 0;
    for (var i = 0; i < this.length; i++) {
      if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0) len += 2; else len ++;
    }
    return len;
  }
  //将字符串拆成字符，并存到数组中
  String.prototype.strToChars = function(){
    var chars = new Array();
    for (var i = 0; i < this.length; i++){
      chars[i] = [this.substr(i, 1), this.isCHS(i)];
    }
    String.prototype.charsArray = chars;
    return chars;
  }
  //判断某个字符是否是汉字
  String.prototype.isCHS = function(i){
    if (this.charCodeAt(i) > 255 || this.charCodeAt(i) < 0)
      return true;
    else
      return false;
  }
  //截取字符串（从start字节到end字节）
  String.prototype.subCHString = function(start, end){
    var len = 0;
    var str = "";
    this.strToChars();
    for (var i = 0; i < this.length; i++) {
      if(this.charsArray[i][1])
        len += 2;
      else
        len++;
      if (end < len)
        return str;
      else if (start < len)
        str += this.charsArray[i][0];
    }
    return str;
  }
  //截取字符串（从start字节截取length个字节）
  String.prototype.subCHStr = function(start, length){
    return this.subCHString(start, start + length);
  }

  export default {
    name: '',
    computed: {
      ...mapGetters(['menus', 'selectNavMenu'])
    },
    mounted: function () {
      this.handleRouter(this.$route.name)
    },
    data: function () {
      return {
        idx: '',
        collapse: false
      }
    },
    watch: {
      menus: function (val) {
        this.handleRouter(val[0].webRouterName)
      },

      '$route': function (val) {
        this.handleRouter(val.name)
      },
    }
    ,
    methods: {
      handleRouter(name) {
        for (let i = 0; i < this.menus.length; i++) {
          let m = this.menus[i]
          if (name.indexOf(m.webRouterName) !== -1) {
            this.$nextTick(() => {
              this.idx = m.webRouterName
            });

            return
          }
        }
      },
      handleSelect(key) {
        if (key.indexOf('-') >= 0) {
          this.$message.warning('当前功能暂未开放')
          return
        }
        if (this.$route.name !== key) {
          this.$router.push({name: key})
        }
      },
      handleEnter() {
        this.collapse = false

      },
      handleLeave() {
        this.collapse = true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .aside-menu {
    transition: $--all-transition;

    [class^=el-icon-] {
      font-size: 18px;
    }

    .aside-menu-item {
      height: $_menu_height;
      line-height: $_menu_height;
      cursor: pointer;
    }

    .aside-menu-item:hover {
      background-color: $-theme-light-8;
      color: $_theme_color;
      cursor: pointer;
    }

    .aside-menu-item {
      min-width: $--aside-max-width;
      margin-top: 8px;
      height: 42px;
      font-size: 13px;
      line-height: 42px;

      div {
        vertical-align: middle;
        line-height: 42px;
        padding-left: 20px;
      }
    }

    .item{
      span{
        color: #333333;
      }
    }

    .aside-menu-item:hover {

    }

    i {
      padding: 4px;
      margin-right: 6px;
    }

    .active {

      span {
        color: $_theme_color;
      }

      i {
        color: white;

        max-width: $--aside-min-width;
        background-color: $_theme_color;
        border-radius: 50%;
      }
    }

    .aside-menu-item.collapse {
      min-width: $--aside-min-width;
    }

    .iconfont {
      /*display: inline-block;*/
      width: 32px;
      height: 32px;
      vertical-align: bottom;
      text-align: center;
      font-size: 18px;
      color: $_theme_color;
    }

    .collapse {
      transition: $--all-transition;
    }
  }
</style>
