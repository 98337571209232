<template>
  <div class="header">
    <!--<div class="logo">hello world</div>-->
        <logo></logo>
    <!--    <p class="slog">物联助力咖啡业务更高效</p>-->
    <div class="empty"></div>
    <!--    <div class="header-right">-->
    <!--      <el-popover>-->
    <!--        <p v-for="enterprise in enterprises" class="switch-company" @click="handleSwitchEnterprise(enterprise)">-->
    <!--          {{enterprise.name}}</p>-->

    <!--        <div slot="reference" class="transfer-company"><span class="company">{{enterprise.name}}</span><span-->
    <!--          class="info" @click="loadEmployeeEnterprise">[切换公司]</span></div>-->
    <!--      </el-popover>-->
    <!--      <ul>-->
    <!--        &lt;!&ndash;<li> <i class="el-icon-bell"></i>通知</li>&ndash;&gt;-->
    <!--        <li><i class="el-icon-document"></i></li>-->
    <!--        &lt;!&ndash;        <li>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class="tips">{{messageNum}}</div>&ndash;&gt;-->
    <!--        &lt;!&ndash;          <i class="el-icon-message-solid"></i>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </li>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <li><i class="el-icon-s-tools"></i></li>&ndash;&gt;-->
    <!--        <li @click="handleChangeTheme">-->
    <!--          <i :class="mode"></i>-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--      <el-dropdown @command="handleCommand">-->
    <!--        <div class="employee-info">-->
    <!--          <span class="liner"></span>-->
    <!--          <img :src="src"/>-->
    <!--          <span>{{employee.name}}</span>-->
    <!--          <i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
    <!--        </div>-->

    <!--        <el-dropdown-menu slot="dropdown">-->
    <!--          <el-dropdown-item command="employee.changePassword">修改密码</el-dropdown-item>-->
    <!--          <el-dropdown-item command="employee.exit">退出登录</el-dropdown-item>-->
    <!--        </el-dropdown-menu>-->
    <!--      </el-dropdown>-->
    <!--    </div>-->

    <c-dialog title="修改密码" :visible.sync="dialogFormVisible" width="32rem" height="24rem">
      <el-form size="mini">
        <el-form-item label="原始密码">
          <el-input v-model="reset.oldPassword" type="password" autocomplete="off" placeholder="请输入原始密码"></el-input>
        </el-form-item>
        <el-form-item label="新的密码">
          <el-input v-model="reset.password" type="password" autocomplete="off" placeholder="请输入新的密码"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码">
          <el-input v-model="reset.repeatedPassword" type="password" autocomplete="off" placeholder="请再次输入新的密码"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleResetPassword">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </c-dialog>
  </div>

</template>

<script>

  import {changeTheme} from '@/assets/theme/setTheme.js'
  import Logo from '@/components/Logo'
  import CDialog from '@/components/Dialog'
  import {mapActions, mapGetters} from 'vuex'
  import {getToken, setToken, removeToken} from '@/util/auth'
  import {checkObj} from '@/util/checkLogin'

  import {employeeBelongEnterprise, employeeSwitchEnterprise, employeeChangePassword} from '@/api/account/employee'

  export default {
    name: '',
    components: {
      Logo,
      CDialog
    },
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
      if (this.employee.avatar) {
        this.src = this.employee.avatar
      }
      this.enterprise = JSON.parse(getToken('enterprise'))

      // let hours = (new Date()).getHours()
      // if (hours < 6 || hours >= 18) {
      //   this.resetTheme('dark')
      // } else {
      //   this.resetTheme('light')
      // }

    },
    data: function () {
      return {
        src: require('@/assets/img/avatar.jpg'),
        messageNum: 5,
        mode: 'el-icon-sunny',
        light: 'el-icon-sunny',
        dark: 'el-icon-moon',
        enterprise: {},
        dialogFormVisible: false,
        reset: {
          oldPassword: '',
          password: '',
          repeatedPassword: '',
        },
        enterprises: []
      }
    },
    methods: {
      ...mapActions(['setTheme']),
      showChangePassword() {
        this.dialogFormVisible = true
        this.reset = {
          oldPassword: '',
          password: '',
          repeatedPassword: '',
        }
      },
      handleResetPassword() {
        let reset = this.reset
        let msg = checkObj(reset, [
          {value: 'oldPassword', message: '请输入原始密码'},
          {value: 'password', message: '请输入新的密码'},
          {value: 'repeatedPassword', message: '请确认新的密码'},
        ])
        if (!msg.status) {
          this.$message.warning(msg.message);
          return
        }
        if (reset.password !== reset.repeatedPassword) {
          this.$message.warning('两次密码不匹配')
          return
        }

        employeeChangePassword(this.reset).then(res => {
          this.dialogFormVisible = false
          this.$message.success('修改成功')
        })
      },
      handleChangeTheme() {
        this.resetTheme(this.mode === this.light ? 'dark' : 'light')
      },
      handleCommand(command) {
        switch (command) {
          case `employee.exit`:
            this.exit();
            break;
          case `employee.changePassword`:
            this.showChangePassword();
            break;
        }
      },
      handleSwitchEnterprise(enterprise) {
        if (this.enterprise.uuid === enterprise.uuid) {
          this.$message.warning('已在当前公司')
          return
        }
        employeeSwitchEnterprise({uuid: enterprise.uuid}).then(res => {
          setToken(res.data.token)
          setToken(JSON.stringify(enterprise), `enterprise`)
          this.$router.replace({name: "login"})
        })
      },

      loadEmployeeEnterprise() {
        if (this.enterprises.length === 0) {
          employeeBelongEnterprise().then(res => {
            this.enterprises = res.data || []
          })
        }
      },
      resetTheme(theme) {
        if (theme === 'light') {
          this.mode = this.light
        } else {
          this.mode = this.dark
        }
        window.document.documentElement.setAttribute('data-theme', theme)
        changeTheme(theme)
        this.$mq.Publish(`theme`, theme)
        this.setTheme(theme)
      },
      exit() {
        removeToken()
        this.$router.replace({name: "login"})
      }
    },
  }
</script>

<style lang="scss">

  .switch-company {
    padding-bottom: $padding-8;
    text-align: center;

  }



  .switch-company:hover {
    color: $_theme_color;
    cursor: pointer;
  }

  .switch-company:last-of-type {
    padding-bottom: 0;
  }

  .header {
    background: $_theme_color;
    .slog {
      /*margin-left: 20px;*/
      font-size: 18px;
      padding-top: 3px;
      color: $_theme_color;
      font-weight: 700;
      line-height: 60px;
    }

    .logo {

    }

    .empty {
      flex: 1;
    }


    .header-right {
      display: flex;
      align-content: center;
      justify-content: center;
      color: $--color-info;


      ul {
        display: flex;
        margin-left: 16px;

        li {
          margin: 0 16px;
          height: 60px;
          position: relative;

          i {
            vertical-align: middle;
            color: $_theme_color;
            font-size: 20px;
          }

          .tips {
            padding: 2px 5px;
            font-size: 12px;
            background-color: #ff2d2c;
            position: absolute;
            right: -12px;
            top: 12px;
            line-height: 1;
            color: #fff;
            @include rounded(5px);
          }
        }
      }

      .transfer-company {
        @include set_bg_color($_theme_color-light-1, $_theme_color-dark-1);
        border-radius: 20px;
        line-height: 36px;
        padding: 0 16px;
        margin-top: 12px;

        .company {
          @include set_font_color($--font-02-color, $--font-color-dark);
        }

        .info {
          display: inline-block;
          margin-left: 6px;
          cursor: pointer;
        }

      }

      .employee-info {
        height: 60px;
        margin: 0 16px;
        cursor: pointer;

        .liner {
          display: inline-block;
          content: ' ';
          height: 24px;
          width: 2px;
          background-color: $--color-info;
        }

        img {
          margin: 0 8px;
          width: 24px;
          height: 24px;
          vertical-align: middle;
          border-radius: 20px;
          border: 1px solid #e4e4e4;

        }

        span {
          color: $--color-info;
          height: 100%;
          vertical-align: middle;
        }
      }
    }
  }
</style>
